import utils from 'o365.modules.utils.js';

export default class CellSelectControl {
    constructor(options) {
        this.selection = options.selection;
        this.dataColumns = options.dataColumns;
        this.dataObject = options.dataObject;

        this.undoRedoService = new UndoRedoService({
            dataObject: this.dataObject
        });
    }

    getRows() {
        if (this.selection?.fullSelection) {
            const rows = this.selection.selectionIsRowPositive
                ? this.dataObject.data.slice(this.selection.start.row, this.selection.end.row + 1)
                : this.dataObject.data.slice(this.selection.end.row, this.selection.start.row + 1);
            return rows;
        } else {
            return []; 
        }
    }




    

    getColumns() {
        if (this.selection?.fullSelection) {
            const columns = this.selection.selectionIsColPositive
                ? this.dataColumns.columns.slice(this.selection.start.col, this.selection.end.col + 1)
                : this.dataColumns.columns.slice(this.selection.end.col, this.selection.start.col + 1);
            return columns;
        } else {
            return [];
        }
    }

    getSelected() {
        if (this.selection?.fullSelection) {
            const rows = this.getRows();
            const columns = this.getColumns();
            const selected = [];
            rows.forEach((row, index) => {
                selected[index] = {};
                columns.forEach(col => {
                    selected[index][col.name] = row[col.name];
                });
            });
            return selected;
        } else {
            return [];
        }
    }

    copySelection(withHeaders = false) {
        const columns = this.getColumns();
        const rows = this.getRows();
        let result = '';
        if (withHeaders) {
            columns.forEach((col, colIndex) => {
                result += col.headerName;
                if (colIndex < columns.length - 1) { result += '\t'; }
            });
            result += '\n';
        }
        rows.forEach((row, rowIndex) => {
            columns.forEach((col, colIndex) => {
                if (col.unbound) {
                    // TODO: Add getter from innreText
                } else {
                    const value = row[col.name];
                    if (value) {
                        result += utils.format(value, col);
                    }
                }
                if (colIndex < columns.length - 1) { result += '\t'; }
            });
            if (rowIndex < rows.length - 1) { result += '\n'; }
        });
        navigator.clipboard.writeText(result);
    }

    async pasteSelection() {
        try {
            const text = await window.navigator.clipboard.readText()
            const parsedText = text.split('\r\n').map(row => row.split('\t'));

            const columns = this.getColumns();
            const rows = this.getRows();

            let parsedColumnLength;
            const isUniform = parsedText.every((col) => {
                if (typeof parsedColumnLength === 'undefined') { parsedColumnLength = col.length; return true; }
                else { return parsedColumnLength === col.length; }
            });

            if (!isUniform) {
                console.error('Paste failed')
                return;
            }

            const dataObject = window[this.dataObject.dataObjectId]
            const changeObject = {};
            const fieldChangedHandler = (prop, value, item) => {
                if (dataObject.fields.getFields(prop)) {
                    if (!changeObject[item._uniqueKey]) { changeObject[item._uniqueKey] = {}; }
                    changeObject[item._uniqueKey][prop] = value;
                }
            };
            if (dataObject) {
                dataObject.on('FieldChanged', fieldChangedHandler);
            }

            if (parsedText.length === 1 && parsedText[0].length === 1) {
                rows.forEach((row) => {
                    columns.forEach((col) => {
                        if (col.editable) {
                            row[col.name] = text;
                        }
                    });
                });
            } else {
                if (parsedText.length >= rows.length && parsedColumnLength >= columns.length) {
                    rows.forEach((row, rowIndex) => {
                        columns.forEach((col, colIndex) => {
                            if (col.editable) {
                                row[col.name] = parsedText[rowIndex][colIndex];
                            }
                        });
                    });
                } else {
                    rows.forEach((row, rowIndex) => {
                        columns.forEach((col, colIndex) => {
                            if (col.editable) {
                                const repeatingRow = rowIndex - Math.floor(rowIndex / parsedText.length) * parsedText.length;
                                const repeatingCol = colIndex - Math.floor(colIndex / parsedColumnLength) * parsedColumnLength;
                                row[col.name] = parsedText[repeatingRow][repeatingCol];
                            }
                        });
                    });
                }
            }

            if (dataObject) {
                dataObject.off('FieldChanged', fieldChangedHandler);
            }

            // this.undoRedoService.pushChange(changeObject);
            // debugger

            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}

export class UndoRedoService {

    constructor(options) {
        this.stack = options.stack ?? [];
        this.stackSize = options.stackSize ?? 20;
        this.dataObject = options.dataObject;
    }

    pushChange(changes) {
        if (this.stack.length > this.stackSize) {
            this.stack = this.stack.slice(1);
        }

        if (this.stack.length === 0) {
            const dirtyData = window[this.dataObject.dataObjectId].storage.getDirtyData();
            const originalData = {};
            for (let changeKey in changes) {
                for (let propKey in changes[changeKey]) {
                    if (!originalData[changeKey]) { originalData[changeKey] = {}; }
                    originalData[changeKey][propKey] = dirtyData[changeKey][propKey];
                }
            }

            this.stack.push(originalData);
        }

        this.stack.push(changes);
    }

    popChange() {
        return this.stack.pop();
    }

}